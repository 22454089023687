import "./App.css"
import React, { useState } from "react"

function App() {
  const [newItem, setNewItem] = useState("")
  const [items, setItems] = useState(["Eggs", "Tomato"])
  const inputRef = React.createRef()

  function addNewItem() {
    if (newItem) {
      setItems([...items, newItem])
      setNewItem("")
      inputRef.current.focus()
    }
  }

  function removeItem(event, index) {
    event.stopPropagation()
    let tmp = [...items]
    tmp.splice(index, 1)
    setItems([...tmp])
  }

  return (
    <div className="App">
      <h4>Add Item</h4>
      <div className="new-item-input-container">
        <input
          className="new-item-input"
          type="text"
          value={newItem}
          onChange={e => setNewItem(e.target.value)}
          onKeyDown={e => e.key === "Enter" && addNewItem()}
          ref={inputRef}
        />
        <button className="new-item-button" onClick={addNewItem}>
          +
        </button>
      </div>
      <ul className="items">
        {items.map((item, index) => (
          <li className="item" key={index.toString()}>
            <span>{item}</span>
            <button
              className="delete-button"
              onClick={e => removeItem(e, index)}
            >
              <span aria-hidden="true">ⓧ</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default App
